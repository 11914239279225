export function initmenu() {
    let open = document.getElementById("menu-open");
    let menu = document.getElementById("main");
    if (open && menu) {
        menuOpen();
        menuClose();
    }

    let invert = document.getElementById("menu-invert");
    if (invert) {
        secret();
    }
}

function menuOpen() {
    let open = document.getElementById("menu-open");
    let menu = document.getElementById("main");
    let blur = document.getElementById("blur");

    open.addEventListener("click", () => {
        menu.classList.remove("inactive");
        blur.classList.remove("inactive");
    });
}

function menuClose() {
    let menu = document.getElementById("main");
    let blur = document.getElementById("blur");
    menu.addEventListener("click", (e) => {
        if (e.target !== e.currentTarget) return;
        menu.classList.add("inactive");
        blur.classList.add("inactive");
    });

    let list = menu.querySelector("li.gap");
    list.addEventListener("click", (e) => {
        if (e.target !== e.currentTarget) return;
        menu.classList.add("inactive");
        blur.classList.add("inactive");
    });
}

function secret() {
    let invert = document.getElementById("menu-invert");

    invert.addEventListener("click", () => {
        document.body.setAttribute('invert-transition', '');
        setTimeout(() => {
            document.body.removeAttribute('invert-transition');
        }, 500)

        document.body.toggleAttribute('invert');
    });
}
