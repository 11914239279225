import { up, down, toggle } from "slide-element";

const timing = 600;
const easing = "ease-in-out";

export function initinformation() {
    let routes = document.querySelectorAll("a.routing-information");
    if (routes) {
        routing();
    }

    let stack = document.querySelectorAll("a.stack-open");
    if (stack) {
        routeStack();
    }

    let personen = document.querySelectorAll(".person-open");
    if (personen) {
        routePersonen();
    }
}

function routing() {
    let routes = document.querySelectorAll("a.routing-information");

    routes.forEach((route) => {
        route.addEventListener("click", (e) => {
            if (!document.body.hasAttribute("fetch")) {
                //DISABLE LINK
                e.preventDefault();

                let el = e.target.closest("a");
                if (!el) {
                    el = e.target;
                }
                let url = el.dataset.href;

                if (url) {
                    if (!el.classList.contains("fetched")) {
                        // LOAD CONTENT
                        const fetchContent = async () => {
                            try {
                                const response = await fetch(url);
                                const { title, slug, html } =
                                    await response.json();
                                buildInformation(html, slug, el);
                            } catch (error) {
                                console.log("Fetch error: ", error);
                            }
                        };
                        el.classList.add("fetched");
                        fetchContent();
                    } else {
                        destroyInformation(el);
                    }
                }
            }
        });
    });
}

function buildInformation(html, slug, el) {
    //TEXT

    let parent = document.querySelector(`section.inhalt[data-slug="${slug}"]`);
    parent.innerHTML = "";
    parent.insertAdjacentHTML("beforeend", html);
    window.lazy.update();
    down(parent, {
        duration: timing,
        easing: "ease-in-out",
        display: "flex"
    }).then(() => {
        // SWITCH TEXT
        let txtswitch = el.innerText;
        let txt = el.dataset.text;
        el.dataset.text = txtswitch;
        el.innerHTML = txt;
    });
}
function destroyInformation(el) {
    let slug = el.dataset.slug;
    let parent = document.querySelector(`section.inhalt[data-slug="${slug}"]`);

    up(parent, {
        duration: timing,
        easing: "ease",
    }).then(() => {
        // SWITCH TEXT
        let txtswitch = el.innerText;
        let txt = el.dataset.text;
        el.dataset.text = txtswitch;
        el.innerHTML = txt;

        parent.innerHTML = "";
        el.classList.remove("fetched");
    });
}

function routeStack() {
    let routes = document.querySelectorAll("a.stack-open");

    routes.forEach((route) => {
        route.addEventListener("click", (e) => {
            //DISABLE LINK
            e.preventDefault();

            let el = e.target.closest("a");
            if (!el) {
                el = e.target;
            }
            let type = el.dataset.stack;

            let parent = document.querySelector(
                `section.stack[data-stack="${type}"]`
            );
            if (parent) {
                toggle(parent, {
                    display: "flex"
                }).then(() => {
                    // SWITCH TEXT
                    let h = el.querySelector("h5");
                    let txtswitch = el.innerText;
                    let txt = el.dataset.text;
                    el.dataset.text = txtswitch;
                    h.innerHTML = txt;
                });
            }
        });
    });
}

function routePersonen() {
    let personen = document.querySelectorAll(".person-open");

    personen.forEach((person) => {
        person.addEventListener("click", (e) => {

            let el = e.target.parentNode.querySelector('.person-bio')
            
            if (el) {
                toggle(el, {
                    display: "flex"
                }).then(() => {
                    // SWITCH TEXT
                    let txtswitch = e.target.innerText;
                    let txt = e.target.dataset.text;
                    e.target.dataset.text = txtswitch;
                    e.target.innerHTML = txt;
                });
            }
        });
    });
}
